$(function () {
    $('body').overlayScrollbars({
        className: "os-theme-light",
    });

    $('[data-toggle="tooltip"]').tooltip({
        html: true,
        placement: 'top'
    });

    $('[data-toggle="popover"]').popover({
        html: true,
        placement: 'top',
        trigger: 'click'
    });

    $('[data-toggle="popover-hover"]').popover({
        html: true,
        trigger: 'hover'
    });

    $('#tooltip-uploader').tooltip({
        html: true,
        placement: 'top',
        template: '<div class="tooltip-inner" style="width: 400px; height: 100%"></div>'
    });

    $(".showDepositoInfos").click(function () {
        $('#modalDonationPlans').modal('hide');
        $('#depositoBancario').modal('show');
    });

    $(".showPickpayInfos").click(function () {
        $('#modalDonationPlans').modal('hide');
        $('#PICPAYm').modal('show');
    });

    $(".showPixInfos").click(function () {
        $('#modalDonationPlans').modal('hide');
        $('#PIXm').modal('show');
    });
});

$(document).ready(function () {
    $('.image-pop').magnificPopup({
        type: 'image',
        tClose: 'Fechar (Esc)',
        tLoading: 'Carregando...',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>', // markup of an arrow button
            tPrev: 'Anterior',
            tNext: 'Próximo',
            tCounter: '<span class="mfp-counter">%curr% de %total%</span>'
        }
    });

    $('.video-popup').magnificPopup({
        type: 'iframe',
        iframe: {
            markup: '<div class="mfp-iframe-scaler">' +
                '<div class="mfp-close"></div>' +
                '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                '<div class="mfp-title"></div>' +
                '</div>'
        },
        callbacks: {
            markupParse: function (template, values, item) {
                values.title = item.el.attr('title');
            }
        },
        tClose: 'Fechar (Esc)',
        tLoading: 'Carregando...',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>', // markup of an arrow button
            tPrev: 'Anterior',
            tNext: 'Próximo',
            tCounter: '<span class="mfp-counter">%curr% de %total%</span>'
        }
    });

    $('a[data-toggle="pill"]').on('shown.bs.tab', function (e) {
        $('.slick-slider').slick('setPosition');
    });

    $('.slickSlide').slick({
        lazyLoad: 'ondemand',
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        autoplaySpeed: 4000,
    });
});